<template>
  <div class="screen">
    <a-row :class="subSlot === 5 ? '' : 'rowBox'">
      <a-col :span="4">
        <slot :name="1"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <slot :name="2"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <slot :name="3"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <slot :name="4"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4" :class="!top ? 'searchBoxfather' : ''">
        <Sub @search="search" @reset="reset" v-if="subSlot === 5" />
        <slot :name="5"></slot>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 10px">
      <a-col :span="4">
        <slot :name="6"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <slot :name="7"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <slot :name="8"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <slot :name="9"></slot>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4" :class="!top ? 'searchBoxfather' : ''">
        <Sub @search="search" @reset="reset" v-if="subSlot !== 5" />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import Sub from "@/components/list/Sub.vue";
export default {
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    subSlot: {
      type: Number,
      default: 10,
    },
    formState: {
      type: Object,
      default: {},
    },
  },
  components: {
    Sub,
  },
  methods: {
    search() {
      this.$store.commit("searchFlagAdd");
      this.$emit("search");
    },
    reset() {
      this.$emit('update:formState',{})
      this.$emit("reset");
      this.search()
    },
  },
};
</script>
<style lang="scss" scoped>
.screen {
  font-size: 12px;
  color: #8c8c8c;
  line-height: 20px;
  font-weight: 400;
  border-radius: 4px;
  background: #fff;
  padding: 20px 40px 8px 40px;
  font-size: 12px;
  .rowBox {
    margin-bottom: 20px;
  }
  div {
    margin-bottom: 5px;
  }
}
.searchBox {
  min-width: 168px;
}
.searchBoxfather {
  position: relative;
  .searchBox {
    position: absolute;
    bottom: -5px;
    left: 0;
  }
}
</style>
<style lang="scss">
.screen .ant-select-selector {
  height: 30px !important;
}
.screen .input .ant-input {
  height: 20px !important;
}
.screen .ant-calendar-range-picker-input {
  height: 20px !important;
}
</style>